$green_dark:#68aa01;
$green_light:#6fb600;
$yellow:#ffd800;
$purple:#7f4acc;
$red:#e5394f;
$white:#ffffff;
$transparent_white:rgba(255,255,255,0.7);

$border_color:#e3eeee;

$header_color:#192d49;
$font_color:#5c7392;

$special_font:'Jaapokki';
$main_font: 'Muli', sans-serif;

@mixin fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim {
    transition: 0.3s all ease;
}

body{
    font-family: $main_font;
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin: 0;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
}

.vertical_align{
    @include fill;
    > div{
        display: table;
        height: 100%;
        width: 100%;
        > div{
            display: table-cell;
            vertical-align: middle;
            height: 100%;
        }
    }
}

.page{
    overflow: hidden;
}

.more_btn{
    padding: 25px 29px;
    font-size: 17px;
    line-height: 17px;
    border-radius: 7px;
    display: inline-block;
    font-weight: 800;
    -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.3);
    @media only screen and (max-width: 1500px) {
        font-size: 14px;
        line-height: 14px;
        padding: 20px 24px;
    }
    &.small{
        font-size: 13px;
        line-height: 13px;
        padding: 16px 27px;
        @media only screen and (max-width: 1200px) {
            font-size: 11px;
            line-height: 11px;
        }
    }
    span{
        margin-left: 7px;
    }
    &.yellow{
        color: $green_dark;
        background: $yellow;
        span{
            color: $white;
        }
    }
    &.transparent{
        color: $header_color;
        background: $transparent_white;
        span{
            color: $white;
        }
    }
    &.transparent2{
        color: $white;
        background: $transparent_white;
        span{
            color: $header_color;
        }
    }
    &.white{
        color: $white;
        background: transparent;
        border: 1px solid $white;
        span{
            color: $yellow;
        }
    }
    &.green{
        color: $white;
        background: $green_light;
        span{
            color: $header_color;
        }
    }
    &.purple{
        color: $white;
        background: $purple;
        span{
            color: $header_color;
        }
    }
}

.default_font{
    font-size: 14px;
    line-height: 30px;
    font-weight: 600;
    color: $font_color;
    @media only screen and (max-width: 1200px) {
        font-size: 12px;
        line-height: 28px;
    }
    p{
        font-weight: inherit;
    }
    h1{
        font-size: 90px;
        line-height: 85px;
        @media only screen and (max-width: 1500px) {
            font-size: 60px;
            line-height: 55px;
        }
        @media only screen and (max-width: 1200px) {
            font-size: 40px;
            line-height: 60px;
        }
    }
    h2{
        font-size: 48px;
        line-height: 58px;
        @media only screen and (max-width: 1500px) {
            font-size: 30px;
            line-height: 40px;
        }

    }
    h3{
        font-size: 30px;
        line-height: 40px;
        @media only screen and (max-width: 1200px) {
            font-size: 24px;
            line-height: 34px;
        }
    }
    h4{
        font-size: 24px;
        line-height: 34px;
        @media only screen and (max-width: 1200px) {
            font-size: 20px;
            line-height: 30px;
        }
    }
    h5{
        font-size: 20px;
        line-height: 30px;
        @media only screen and (max-width: 1200px) {
            font-size: 17px;
            line-height: 27px;
        }
    }
    h6{
        font-size: 17px;
        line-height: 27px;
        @media only screen and (max-width: 1200px) {
            font-size: 14px;
            line-height: 24px;
        }
    }
    ul{
        padding-left: 18px;
        list-style: disc;
    }
    ol{
        padding-left: 18px;
    }
    a{
        font-weight: 800;
        text-decoration: underline;
        color: $green_light;
    }
    h1,h2,h3,h4,h5,h6{
        color: $header_color;
    }
    s{
        color: $green_light;
        text-decoration: none;
    }
    h1,h2,h3,h4,h5,h6,p,ul,ol,a{
        margin-bottom: 15px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

header.header{
    position: relative;
    z-index: 10;
    div{
        &.header_left{
            padding-top: 22px;
            .logo_box{
                position: relative;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 15px;
                }
                img{
                    max-width: 100%;
                    height: auto;
                    @media only screen and (max-width: 991px) {
                       max-width: 100px;
                    }
                }
                .rwd_btn{
                    width: 30px;
                    position: absolute;
                    top: 20px;
                    right: 0;
                    display: none;
                    @media only screen and (max-width: 991px) {
                       display: block;
                    }
                    > div{
                        width: 100%;
                        background: $green_light;
                        height: 3px;
                        margin-bottom: 3px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .near_logo_list{
                padding-top: 25px;
                @media only screen and (max-width: 1200px) {
                    padding-top: 5px;
                }
                @media only screen and (max-width: 991px) {
                      display: none;
                }
                > ul{
                    position: relative;
                    display: inline-block;
                    &:before{
                        content: '';
                        height: 100%;
                        width: 1px;
                        background: $green_light;
                        position: absolute;
                        top: 0;
                        left: 24px;
                    }
                    > li{
                        text-align: left;
                        font-size: 14px;
                        line-height: 14px;
                        font-weight: 800;
                        color: $font_color;
                        margin-bottom: 15px;
                        &:last-child{
                            margin-bottom: 0;
                        }
                        span{
                            color: $header_color;
                            margin-right: 25px;
                        }
                    }
                }
            }
        }
        &.header_top{
            @media only screen and (max-width: 991px) {
                display: none;
            }
            .menu_box{
                nav.menu{
                    text-align: right;
                    > ul{
                        display: inline-block;
                        vertical-align: middle;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                        }
                        &:last-child{
                            > li{
                                text-align: center;
                                min-width: 110px;
                                border-right: 1px solid $border_color;
                                padding: 52px 35px;
                                display: block;
                                @media only screen and (max-width: 1500px) {
                                    min-width: 90px;
                                    padding: 38px 21px;
                                }
                                @media only screen and (max-width: 1200px) {
                                    min-width: auto;
                                    padding: 37px 21px;
                                }
                                @media only screen and (max-width: 991px) {
                                    padding: 10px 0;
                                    width: 100%;
                                    border-right: none;
                                }
                            }
                        }
                        @media only screen and (max-width: 991px) {
                            padding-top: 15px;
                        }
                        > li{
                            display: inline-block;
                            vertical-align: bottom;
                            text-align: center;
                            position: relative;
                            &:hover{
                                > ul{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            > ul{
                                visibility: hidden;
                                opacity: 0;
                                @include anim;
                                position: absolute;
                                top: 100%;
                                left: 0;
                                width: 350px;
                                background: #528600;
                                z-index: 7;
                                text-align: left;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    position: relative;
                                    top: auto;
                                    opacity: 1;
                                    visibility: visible;
                                    text-align: center;
                                    background: transparent;
                                    padding-top: 15px;
                                }
                                > li{

                                    border-bottom: 1px solid $green_dark;
                                    width: 100%;

                                    @include anim;
                                    @media only screen and (max-width: 991px) {
                                        padding: 0;
                                        text-align: center;
                                        margin-bottom: 15px;
                                        border-bottom: none;
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                    }
                                    &:hover{
                                        background: $green_dark;
                                        a{
                                            color: $white;
                                        }
                                    }
                                    > div{
                                        &.on{
                                            background: $green_dark;
                                            @media only screen and (max-width: 991px) {
                                                background: $green_light;
                                            }
                                            > a{
                                                font-weight: 800;
                                                color: $white;
                                            }
                                        }
                                        > a{
                                            color: $white;
                                            font-size: 15px;
                                            font-weight: 600;
                                            line-height: 15px;
                                            padding: 0;
                                            border: none;
                                            font-weight: 600;
                                            padding: 24px 41px;
                                            display: block;
                                            @media only screen and (max-width: 1500px) {
                                                font-size: 13px;
                                                line-height: 13px;
                                                min-width: 90px;
                                                padding: 42px 21px;
                                            }
                                            @media only screen and (max-width: 1200px) {
                                                font-size: 11px;
                                                line-height: 11px;
                                                min-width: auto;
                                            }
                                            @media only screen and (max-width: 991px) {
                                                padding: 10px 0;
                                                width: 100%;
                                                border-right: none;
                                                display: block;
                                                color: $header_color;
                                                font-weight: 800;
                                            }
                                        }
                                    }
                                }
                            }
                            @media only screen and (max-width: 991px) {
                                margin-bottom: 15px;
                                width: 100%;
                            }
                            &:first-child{
                                a{
                                    border-left: 1px solid $border_color;
                                    @media only screen and (max-width: 991px) {
                                        border-left: none;
                                    }
                                }
                            }
                           > div{
                                &.on{
                                   > a{
                                        color: $white;
                                        &:after{
                                            height: 100%;
                                        }
                                    }
                                }
                                > a{
                                    position: relative;
                                    @include anim;
                                    &:hover{
                                        color: $white;
                                        &:after{
                                            height: 100%;
                                        }
                                    }
                                    &:after{
                                        z-index: -1;
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        height: 0%;
                                        width: 100%;
                                        background: $green_dark;
                                        @include anim;
                                    }
                                }
                                a{
                                    position: relative;
                                    border-right: 1px solid $border_color;
                                    font-weight: 800;
                                    color: $header_color;
                                    font-size: 16px;
                                    line-height: 16px;
                                    padding: 55px 35px;
                                    display: block;
                                    min-width: 110px;
                                    @media only screen and (max-width: 1500px) {
                                        font-size: 13px;
                                        line-height: 13px;
                                        min-width: 90px;
                                        padding: 42px 21px;
                                    }
                                    @media only screen and (max-width: 1200px) {
                                        font-size: 11px;
                                        line-height: 11px;
                                        min-width: auto;
                                    }
                                    @media only screen and (max-width: 991px) {
                                        padding: 10px 0;
                                        width: 100%;
                                        border-right: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.header_bottom{
            position: relative;
            .text-right{
                padding-top: 13px;
            }
            div.fast_offer{
                background-color: $green_light;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 30px;
                    padding: 20px 15px;
                    text-align: center;
                }
                div.icon, div.fast_offer_desc{
                    display: inline-block;
                    vertical-align: middle;
                }
                div.icon{
                    width: 72px;
                    height: 0;
                    padding-bottom: 72px;
                    background-color: $green_dark;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url('./../img/star_icon.png');
                    @media only screen and (max-width: 991px) {
                       display: none;
                    }
                }
                div.fast_offer_desc{
                    color: $white;
                    font-size: 16px;
                    line-height: 16px;
                    width: calc(100% - 72px);
                    padding-left: 20px;
                    font-weight: 800;
                    @media only screen and (max-width: 1500px) {
                        font-size: 13px;
                        line-height: 13px;
                    }
                    @media only screen and (max-width: 991px) {
                       width: 100%;
                       line-height: 20px;
                    }
                    p{
                        font-weight: inherit;
                    }
                }
            }
            .side_width{
                z-index: -1;
                position: absolute;
                top: 0;
                left: 100%;
                height: 100%;
                background: $green_light;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
            }
        }
    }
}

section.slider{
    position: relative;
    //height: 635px;
    height: 71vh;
    width: 100%;
    @media only screen and (max-width: 991px) {
       height: 300px;
    }
    > ul{
        @include fill;
        > li{
            @include fill;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            div.slider_content{
                position: relative;
                z-index: 3;
                //padding-top: 120px;
                padding-top: 5%;
                @media only screen and (max-width: 1500px) {
                    padding-top: 7%;
                }
                @media only screen and (max-width: 1200px) {
                    padding-top: 5%;
                }
                @media only screen and (max-width: 991px) {
                    text-align: center;
                }
                header{
                    font-family: $special_font;
                    margin-bottom: 25px;
                    h1{
                        color: $header_color;
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-bottom: 10px;
                    }
                }
                div.desc{
                    //width: 80%;
                    font-size: 14px;
                    line-height: 34px;
                    margin-bottom: 40px;
                    font-weight: 600;
                    color: $font_color;
                    padding: 15px;
                    background: rgba(255,255,255,0.8);
                    border-bottom: 2px solid $green_light;
                    min-height: 100px;
                    @media only screen and (max-width: 1500px) {
                        font-size: 13px;
                        line-height: 33px
                    }
                    @media only screen and (max-width: 1200px) {
                        margin-bottom: 20px;
                    }
                    @media only screen and (max-width: 991px) {
                        display: none;
                    }
                    p{
                        font-weight: inherit;
                    }
                }
            }
        }
    }
    > .dragon{
        width: 24%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        img{
            max-width: 100%;
            height: auto;
        }
        @media only screen and (max-width: 991px) {
            display: none;
        }
    }
    .slider_nav{
        width: 100%;
        position: absolute;
        bottom: 75px;
        left: 0;
        z-index: 2;
        @media only screen and (max-width: 1200px) {
           bottom: 20px;
        }
        .slider_dots{
            text-align: center;
            > ul{
                > li{
                    display: inline-block;
                    vertical-align: top;
                    width: 19px;
                    height: 0;
                    padding-bottom: 15px;
                    border: 2px solid #aac8c8;
                    border-radius: 50%;
                    background: $white;
                    margin-right: 20px;
                    cursor: pointer;
                    @include anim;
                    -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.3);
                    -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.3);
                    box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.3);
                    &.on{
                        background: $green_light;
                        border: 2px solid $green_light;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

header.section_header{
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: $special_font;
    }
    &.header_underline{
        margin: 60px 0 0 0;
        @media only screen and (max-width: 991px) {
            margin: 30px 0;
        }
        > div.default_font{
            color: $header_color;
            &:first-child{
                position: relative;
                padding-bottom: 23px;
                margin-bottom: 50px;
                @media only screen and (max-width: 991px) {
                    margin-bottom: 30px;
                }
                &:after{
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: '';
                    width: 144px;
                    height: 13px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-image: url('./../img/section_header_underline.png');
                    @media only screen and (max-width: 991px) {
                        right: 0;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    font-size: 24px;
    line-height: 42px;
    font-weight: 600;
    color: $header_color;
    @media only screen and (max-width: 1500px) {
        font-size: 18px;
        line-height: 36px;
    }
    > p{
        font-weight: inherit;
    }
    &.purple{
        div.default_font{
            h1,h2,h3,h4,h5,h6{
                color: $purple;
            }
        }
    }
}

.short_about{
    .container{
        > div.row{
            margin-left: -32.5px;
            margin-right: -32.5px;
            @media only screen and (max-width: 991px) {
                margin-left: -15px;
                margin-right: -15px;
            }
            > div{
                padding-left: 32.5px;
                padding-right: 32.5px;
                @media only screen and (max-width: 991px) {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }
    .about_left{
        div.desc{
            margin-bottom: 30px;
        }
    }
    .image{
        &:first-child{
            margin-top: 25%;
        }
        width: 100%;
        height: 0;
        padding-bottom: 70%;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: auto 100%;
        margin-bottom: 30px;
        @media only screen and (max-width: 1200px) {
            margin-bottom: 30px;
        }
        @media only screen and (max-width: 991px) {
            margin-top: 0;
        }
    }
    .more_about{
        padding: 45px 20px 45px 50px;
        border: 2px solid $yellow;
        @media only screen and (max-width: 991px) {
            padding: 20px;
        }
        .row{
            margin-left: 0;
            margin-right: 0;
            > div{
                padding-left: 0;
                padding-right: 0;
                &.text-right{
                    padding-top: 15px;
                }
            }
        }
    }
}

section.boxes{
    padding:40px 0 70px 0;
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    .container{
        > div.row{
            margin-left: -40px;
            margin-right: -40px;
            @media only screen and (max-width: 1200px) {
                margin-left: -15px;
                margin-right: -15px;
             }
            > div{
                padding-left: 40px;
                padding-right: 40px;
                @media only screen and (max-width: 1200px) {
                    padding-left: 15px;
                    padding-right: 15px;
                }
                div.box{
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                    position: relative;
                    @media only screen and (max-width: 1500px) {
                        .more_btn{
                            font-size: 11px;
                            line-height: 11px;
                        }
                    }
                    .background{
                        position: absolute;
                        left: 0;
                        bottom:0;
                        width: 100%;
                    }
                    .box_header{
                        padding: 60px 45px 0 45px;
                        font-weight: 600;
                        position: relative;
                        z-index: 2;
                        @media only screen and (max-width: 991px) {
                            padding: 30px 15px 0 15px;
                            text-align: center;
                        }
                        p{
                            font-weight: inherit;
                        }
                        h2{
                            font-size: 60px;
                            line-height: 56px;
                            font-family: $special_font;
                            margin-bottom: 10px;
                            @media only screen and (max-width: 1500px) {
                                font-size: 40px;
                                line-height: 36px;
                            }
                        }
                        font-size: 22px;
                        @media only screen and (max-width: 991px) {
                            font-size: 15px;
                            .text-right{
                                margin-top: 20px;
                            }
                        }
                    }
                    &.yellow{
                        @media only screen and (max-width: 1200px) {
                            margin-bottom: 50px;
                        }
                        background: #f9e34e;
                        .box_header{
                            h2{
                                color: $header_color;
                            }
                            color: #867b32;
                        }
                        .background{
                            height: 15%;
                            background: #e0cc46;
                        }
                        .moving_element{
                            img{
                                margin-left: -4vw;
                                max-width: 114%;
                            }
                        }
                    }
                    &.red{
                        background: $red;
                        .box_header{
                            h2{
                                color: $white;
                            }
                            color: #712932;
                        }
                        .background{
                            height: 100%;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: cover bottom;
                            background-size: cover;
                            background-image: url('./../img/snow_box.png');
                        }
                        .moving_element{
                            width: 46%;
                            img{
                                margin-right: -7vw;
                                max-width: 143%;
                            }
                        }
                    }
                    .moving_element{
                        position: absolute;
                        bottom: 30px;
                        right: 0;
                        @media only screen and (max-width: 991px) {
                            //display: none;
                        }
                    }
                }
            }
        }
    }
}

footer.footer{
    .footer_logotypes{
        padding: 20px 0;
        text-align: center;
        @media only screen and (max-width: 768px) {
            padding: 10px 0;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: middle;
                margin-right: 50px;
                @media only screen and (max-width: 991px) {
                    margin-right: 30px;
                }
                @media only screen and (max-width: 768px) {
                    margin-right: 0;
                    width: 100%;
                    margin-bottom: 30px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &:last-child{
                    margin-right: 0;
                }
                img{
                    height: 50px;
                    width: auto;
                    @media only screen and (max-width: 991px) {
                        height: 30px;
                    }
                }
            }
        }
    }
    .general_footer{
        background:$green_dark;
        color: $transparent_white;
        font-size: 15px;
        font-weight: 600;
        @media only screen and (max-width: 991px) {
            text-align: center;
            padding:10px 0 30px 0;
        }
    }
    @media only screen and (max-width: 1200px) {
        font-size: 13px;
    }

    .table_box{
        display: table;
        width: 100%;
        @media only screen and (max-width: 991px) {
            display: flex;
            flex-flow: column;
        }
        > div{
            display: table-cell;
            vertical-align: middle;
            width: 46%;
            @media only screen and (max-width: 991px) {
                width: 100%;
                display: block;
                align-items: stretch;
                &:nth-child(1){
                    order: 3;
                }
                &:nth-child(2){
                    order: 1;
                }
                &:nth-child(3){
                    order: 2;
                }
            }
            &.to_up{
                height: 150px;
                width: 8%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    background-color: transparent;
                    height: 50px !important;
                }
                background-color: $green_light;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('./../img/to_up_arr.png');
                cursor: pointer;
                @media only screen and (max-width: 1200px) {
                    height: 110px;
                }
            }
        }
    }
    p,a{
        font-weight: inherit;
    }
    .text-right{
        ul{
            li{
                display: inline-block;
                vertical-align: middle;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    div.copy_right{
        margin-bottom: 20px;
        color: $white;
    }
    div.devs{
        margin-bottom: 20px;
        color: $white;
        ul{
            li{
                margin-right: 15px;
            }
        }
    }
    div.menu{
        nav{
            ul{
                li{
                    margin-right: 30px;
                    @media only screen and (max-width: 1200px) {
                        margin-right: 15px;
                    }
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 15px;
                    }
                    a{
                        color: $transparent_white;
                    }
                }
            }
        }
    }
}

.text-right{
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
}

.top{
    height: 140px;
    position: relative;
    width: 100%;
    background: $yellow;
    margin-bottom: 30px;
    @media only screen and (max-width: 1200px) {
       height: 100px;
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
        height: auto;
        padding: 30px 0;
        .vertical_align{
            height: auto;
            position: relative;
            > div{
                display: block;
                > div{
                    display: block;
                }
            }
        }
    }
    header.page_header{
        h1{
            font-size: 68px;
            line-height: 68px;
            color: $header_color;
            font-family: $special_font;
            @media only screen and (max-width: 1500px) {
                font-size: 52px;
                line-height: 48px;
            }
            @media only screen and (max-width: 1200px) {
                font-size: 32px;
                line-break: 28px;
            }
        }
    }
    nav.nav_way{
        margin-top: 30px;
        @media only screen and (max-width: 1500px) {
            margin-top: 15px;
        }
        @media only screen and (max-width: 1200px) {
            margin-top: 20px;
        }
        @media only screen and (max-width: 991px) {
            margin-top: 10px;
        }
        ul{
            li{
                display: inline-block;
                vertical-align: top;
                margin-right: 12px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 15px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                &.on{
                    a{
                        font-weight: 800;
                        text-decoration: underline;
                    }
                }
                &:last-child{
                    margin-right: 0;
                    a{
                        &:after{
                            display: none;
                        }
                    }
                }
                a{
                    display: inline-block;
                    font-size: 13px;
                    line-height: 13px;
                    color: $header_color;
                    position: relative;
                    font-weight: 600;
                    &:after{
                        color: $white;
                        content:'>';
                        padding-left: 12px;
                        @media only screen and (max-width: 991px) {
                           display: none;
                        }
                    }
                }
            }
        }
    }
}

section.dragon_family, .offer{
    margin-bottom: 30px;
    header{
        margin-bottom: 40px;
        h2{
            font-size: 24px;
            line-height: 42px;
            font-weight: 600;
        }
        color: $header_color;
    }
    figure{
        width: 100%;
        height: 0;
        padding-bottom: 45%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% auto;
    }

}

section.offer{
    padding-bottom: 30px;
    @media only screen and (max-width: 991px) {
        .desc{
            margin-bottom: 30px;
        }
    }
    .container{
        > div.row{
            margin-left: -60px;
            margin-right: -60px;
            @media only screen and (max-width: 1200px) {
                margin-left: -15px;
                margin-right: -15px;
            }
            > div{
                padding-left: 60px;
                padding-right: 60px;
                @media only screen and (max-width: 1200px) {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }
    .image{
        margin-bottom: 30px;
    }
    .questions{
        .question_list{
            ul{
                li{
                    margin-bottom: 30px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    header{
                        margin-bottom: 15px;
                        h2{
                            font-weight: 600;
                        }
                        s{
                            text-decoration: none;
                            color: $green_light;
                        }
                    }
                }
            }
        }
    }
    &.contact{
        div.contact_data{
            margin-bottom: 15px;
            color: $header_color;

        }
        div.contact_fotm{
            div.holder{
                margin-bottom: 17px;
                label{
                    color: $font_color;
                    font-weight: 600;
                    margin-bottom: 10px;
                    font-size: 12px;
                }
                input{
                    border: none;
                    outline: none;
                    border-radius: 5px;
                    &.input{
                        height: 50px;
                        width: 100%;
                        padding: 0 15px;
                        background: #ecf2f5;
                        font-size: 16px;
                        font-weight: 600;
                    }
                    &.submit{
                        margin-top: 13px;
                        height: 44px;
                        width: 185px;
                        background: $yellow;
                        color: #21470c;
                        font-size: 13px;
                        font-weight: 800;
                    }
                }
                textarea.textarea{
                    width: 100%;
                    height: 150px;
                    width: 100%;
                    padding: 15px;
                    background: #ecf2f5;
                    border: none;
                    resize: none;
                    background: #ecf2f5;
                    outline: none;
                    font-size: 16px;
                }
            }
        }
    }
}

figure.gal_image{
    width: 100%;
    height: 0;
    padding-bottom: 70%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    > .vertical_align{
        text-align: center;
        @include anim;
        background: rgba(27,47,75,0.7);
        opacity: 0;
        visibility: hidden;
    }
}

section.galery{
    margin-bottom: 20px;
    .gal_list{
        > ul{
            margin: 0 -15px;
            > li{
                margin-bottom: 30px;
                display: inline-block;
                vertical-align: top;
                width: 25%;
                padding: 0 15px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
        }
    }
    &.gal_subpage{
        padding-top: 20px;
        .gal_image{
            &:hover{
                .vertical_align{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .container{
        > div.row{
            margin-left: -20px;
            margin-right: -20px;
            > div{
                padding-left: 20px;
                padding-right: 20px;
                margin-bottom: 40px;
            }
        }
    }
    article{
        &:hover{
            .gal_image{
                .vertical_align{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    div.desc{
        padding-top: 15px;
        font-size: 13px;
        line-height: 24px;
        color: $font_color;
        font-weight: 600;
        h3, p{
            font-weight: inherit;
        }
        h3{
            color: $header_color;
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 15px;

        }
    }
}

.map{
    margin-top: 30px;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    position: relative;
    @media only screen and (max-width: 991px) {
        margin-bottom: 30px;
    }
    > iframe{
        @include fill;
    }
}

.logotypes{
    > ul{
        > li{
            display: inline-block;
            vertical-align: middle;
            margin-right: 30px;
        }
    }
}

.info_popup_holder{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0,0,0,0.7);
    @media only screen and (max-width: 991px) {
        overflow-y: scroll;
    }
    .info_popup{
        @include fill;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;
        @media only screen and (max-width: 991px) {
            display: block;
        }
        img{
            position: absolute;
            left: calc(100% - 110px);
            bottom: -50px;
            border-radius: 0;
            max-width: 200px;
            @media only screen and (max-width: 991px) {
                display: none;
            }
        }
        .close_popup{
            width: 30px;
            position: absolute;
            top: 25px;
            right: 25px;
            border-radius: 50%;
            color:$white;
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            height: 30px;
            background: $green_light;
            cursor: pointer;
        }
        .default_font{
            margin-bottom: 30px;
            font-style: italic;
        }
        .more_btn{
            cursor: pointer;
            @media only screen and (max-width: 991px) {
                width: 100%;
            }
        }
        .popup_content{
            position: relative;
            padding: 30px;
            background: $white;
            border: 2px solid $green_light;
            border-radius: 20px;
            padding-right: 60px;
            @media only screen and (max-width: 991px) {
                padding-right: 30px;
                padding-top: 60px;
            }
        }
    }
}
